import React from 'react';
import PropTypes from 'prop-types';

import Card from '@components/containers/Card';
import FlexBox from '@components/containers/FlexBox';

import Paragraph from '@components/elements/Paragraph';
import Headline from '@components/elements/Headline';

const AccountDetailsCard = ({
  title,
  loading,
  mainAmountTitle,
  mainAmountValue,
  badges,
  publicId,
  accountDetails,
}) => (
  <Card loading={loading}>
    <FlexBox alignItems="center" flexWrap="wrap" justifyContent="space-between">
      <FlexBox alignItems="center" marginRight="small">
        <Headline fontWeight="bold" fontSize="tiny" as="h2" marginBottom="none" marginRight="small">{title}</Headline>
        <FlexBox flexDirection="row" gap="small">
          {badges}
        </FlexBox>
      </FlexBox>
      <Paragraph fontSize="smaller" fontWeight="light" marginTop="none">{publicId}</Paragraph>
    </FlexBox>
    <FlexBox justifyContent="space-between" flexWrap="wrap">
      <FlexBox
        paddingRight="regular"
        paddingLeft="regular"
        paddingTop="medium"
        paddingBottom="medium"
        alignItems="center"
        flexDirection="column"
        backgroundColor="secondaryLightGray2"
        marginBottom="regular"
        marginTop="regular"
        maxWidthLargeScreen="10.5rem"
        breakSize="tablet"
        fullWidth
      >
        <Paragraph fontWeight="bold" fontSize="big" marginBottom="none">{mainAmountValue}</Paragraph>
        <Paragraph marginTop="none">{mainAmountTitle}</Paragraph>
      </FlexBox>
      <FlexBox
        flexDirection="column"
        flexGrow="1"
        justifyContent="center"
        maxWidthLargeScreen="17rem"
        breakSize="tablet"
      >
        {accountDetails.map((detail) => (
          <FlexBox justifyContent="space-between" key={detail.title}>
            <Paragraph marginBottom="none">{detail.title}:</Paragraph>
            <Paragraph fontWeight="bold" fontSize="regular" marginTop="none">{detail.value}</Paragraph>
          </FlexBox>
        ))}
      </FlexBox>
    </FlexBox>
  </Card>
);

AccountDetailsCard.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  mainAmountTitle: PropTypes.string.isRequired,
  mainAmountValue: PropTypes.string.isRequired,
  badges: PropTypes.arrayOf(PropTypes.node).isRequired,
  publicId: PropTypes.string.isRequired,
  accountDetails: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
};

export default AccountDetailsCard;
